import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { PAGES } from '../config/constants';
import Layout from '../components/layout';
import Heading from '../components/heading';

const Pre = styled.pre`
  margin-bottom: 4rem;

  p, pre {
    margin: 0 0 .5rem;
    
    @media screen and (max-width: 480px) {
      font-size: 1.0625rem;
      line-height: 1.5625rem;
    }
  }
  
  span.first-letter {
    display: inline-block;
    
    &::first-letter {
      font-size: 150%;
    }
  }
  
  ${props => props.justify && css`
    text-align: justify;
    white-space: pre-wrap;
  `}
`;

const Italic = styled.span`
  font-style: italic;
`;

const Poem = ({ path, pageContext }) => {
  const { title, subtitle, justify, markdown, from, fromPath, publisher, year } = pageContext;

  const __html = markdown
    // Curly brackets are used around words that should have a larger first letter e.g. '{Like} this'.
    .replace(/{([\w]+?)}/g, '<span class="first-letter">$1</span>')
    // Contentful adds code tags that we don't want.
    .replace(/<code>([\s\S]+?)<\/code>/g, '$1');

  const layoutProps = {
    breadcrumbTrail: [
      PAGES.POEMS,
      {
        path,
        label: title,
      },
    ],
    pageTitle: title,
    pageDescription: 'A poem by Linda Black' + (from && publisher
      ? ` from her collection '${from}', published by ${publisher}`
      : ''),
  };

  return (
    <Layout {...layoutProps}>
      <Heading title={title} subtitle={subtitle}/>
      <Pre dangerouslySetInnerHTML={{ __html }} justify={justify}/>
      <p>
        {from && <><Italic>from</Italic> <Link to={fromPath}>‘{from}’</Link></>}
        {' '}
        {(publisher || year) && <>({[publisher, year].join(' ')})</>}
      </p>
    </Layout>
  );
};

export default Poem;
